import React from 'react'
import YouTube from 'react-youtube';

const YouTubeEmbed = ( {url, classNames} ) => {

    let videoID   = false;
    let urlObject = new URL(url);

    let params    = urlObject.searchParams;

    const opts = {
        playerVars: {
            modestbranding: 1,
        },
    }

    if ( params.get( 'v' ) ) {
        videoID   = params.get( 'v' );
    } else {
        videoID   = urlObject.pathname.replace(/^(\/)+/, '');
    }
    
    if ( ! videoID )
        return null

    return (
        <div className="relative h-0 overflow-hidden max-w-full pb-[56.25%]">
            <YouTube 
                videoId={videoID}
                className={classNames}
                opts={opts}
            />
        </div>
    )
}

export default YouTubeEmbed